const setErrorMessage=async(message)=>{

    document.getElementById("errordiv").innerText = message;

    setTimeout(function(){
        document.getElementById("errordiv").innerText = "";
    }, 10000);

}


export {setErrorMessage}