
import React from "react"
import { useNavigate } from 'react-router-dom'

import { useEffect } from 'react';
const Routeinghome = () => {
    const navigate = useNavigate();

    useEffect(() => {

        navigate('/');

    }, []);


}

export default Routeinghome