
import React from "react"
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';



const extraFunctie = () =>{
    
}



const Rooms = () => {

    // create navigation object if needed
    const navigate = useNavigate();



    //dop something after loading page
    useEffect(() => {

        try {
            // call on backend here if so that app does not crash and spews error code


            //simulatie van backendcall zodat code werkt
            const data = async () => {

            };

            //wachten op response van server. code binnen de brackets uitvoeren wanner de response er is (=callback function)
            data.then(response => {
                if (response === 200) {
                    //dosomething with data
                    const contentdiv = document.getElementById("content")
                } else {
                    throw new Error(response.status + ":" + response.message)
                }
            })
        } catch (error) {
            setErrorMessage(error.message)
        }


    }, []);

    return (
        <>
            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id="content">
                    <form className="form" >
                    <label className='formtitle'>
                                Room
                            </label>
                        <div className="Roomformchild">
                            <label className='labelhotel'>
                                Hotel naam:
                            </label>
                            <input id="hotelnaam" className="normalinput" type='text' disabled ></input>
                            <label className='labelhotel'>
                                Hotel&nbsp;department&nbsp;nummer:
                            </label>
                            <input id="afdeling"className="normalinput" type='number' disabled></input>
                        </div>
                        <div className="Roomformchild">
                            <label className='labelhotel'>
                                Kamer&nbsp;nummer:
                            </label>
                            <input id="afdeling"className="normalinput" type='number' disabled></input>
                            </div>
                        <div className="Roomformchild_elipsis">
                            <label className='labelhotel'>
                                Kamer&nbsp;type:
                            </label>
                            <select className="select">
                                <option value="suite">suite</option>
                                <option value="kamer">kamer</option>
                                <option value="type2">Variabel</option>
                                <option value="type3">Type2</option>
                            </select> <Button id="saveButton" className='buttonelipsis' >...</Button>
                        </div>
                       {/* <Accordion>
                            <Accordion.Item eventKey="0" className="CleaningTask">
                            <Accordion.Header>Blijver</Accordion.Header>
                            <Accordion.Body>
                                <div className="Roomformchild">
                                    <label className='labelhotel'>
                                        kuistijd:
                                    </label>
                                    <input id="afdeling"className="normalinput" type='number' disabled></input>
                                </div>
                                <div id="startjob" className="buttoncontainer" >
                                <div  className="small_button">voeg kuistaak toe</div>
                            </div>
                                <div id="Cleaning_subtasks" >
                                    <ul>
                                        <li>Stofzuigen: 5min</li>
                                        <li>Bed opmaken: 5min</li>
                                        <li>Keuken poetsen: 10min</li>
                                        <li>Handdoeken vervangen: 2min</li>                        
                                    </ul>
                                </div>
                            </Accordion.Body>
                            </Accordion.Item>            
                        </Accordion> */}
                        {/* <Accordion>
                            <Accordion.Item eventKey="0" className="CleaningTask">
                            <Accordion.Header>Vertrekker</Accordion.Header>
                            <Accordion.Body>
                                <div className="Roomformchild">
                                    <label className='labelhotel'>
                                        kuistijd:
                                    </label>
                                    <input id="afdeling"className="normalinput" type='number' disabled></input>
                                </div>
                                <div id="Cleaning_subtasks" >
                                    <ul>
                                        <li>Stofzuigen: 5min</li>
                                        <li>Bed opmaken: 5min</li>
                                        <li>Keuken poetsen: 10min</li>
                                        <li>Handdoeken vervangen: 2min</li>                        
                                    </ul>
                                </div>
                            </Accordion.Body>
                            </Accordion.Item>            
                        </Accordion> */}
                        {/* <Accordion>
                            <Accordion.Item eventKey="0" className="CleaningTask">
                            <Accordion.Header>Variable [enkel als variabele kamertype is gekozen]</Accordion.Header>
                            <Accordion.Body>
                                <div className="Roomformchild">
                                    <label className='labelhotel'>
                                        kuistijd:
                                    </label>
                                    <input id="afdeling"className="normalinput" type='number' disabled></input>
                                </div>
                                
                                <div id="Cleaning_subtasks" >
                                    <ul>
                                        <li>Stofzuigen: 5min</li>
                                        <li>Bed opmaken: 5min</li>
                                        <li>Keuken poetsen: 10min</li>
                                        <li>Handdoeken vervangen: 2min</li>                        
                                    </ul>
                                </div>
                            </Accordion.Body>
                            </Accordion.Item>            
                        </Accordion> */}
                        <div className="two_Buttons">
                            <Button id="saveButton" className='Savebutton' >Save</Button>
                            <Button id="cancelButton" className='Savebutton' >Cancel</Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )

}

export default Rooms