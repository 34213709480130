
import hotelcss from './css/hotelhotellist.module.css';
import React from "react"
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";

import './css/tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from 'react-bootstrap/Button';
import { MdEdit, MdCheck } from "react-icons/md";



const extraFunctie = () => {

}



const AssignCleaningTasks = (props) => {


    const t = props.translation
    const [Edit, setEdit] = useState(true);

    //t("CheckIn")
    // create navigation object if needed
    const navigate = useNavigate();



    //dop something after loading page
    useEffect(() => {

        try {
            // call on backend here if so that app does not crash and spews error code


            //simulatie van backendcall zodat code werkt
            const data = async () => {

            };

            //wachten op response van server. code binnen de brackets uitvoeren wanner de response er is (=callback function)
            data.then(response => {
                if (response === 200) {
                    //dosomething with data
                    const contentdiv = document.getElementById("content")
                } else {
                    throw new Error(response.status + ":" + response.message)
                }
            })
        } catch (error) {
            setErrorMessage(error.message)
        }
        document.getElementById("editDiv").style.display = "grid";
        document.getElementById("saveDiv").style.display = "none";

    }, []);

    const handleEdit = () => {
        setEdit(false)

        document.getElementById("editDiv").style.display = "none";
        document.getElementById("saveDiv").style.display = "grid";
    }
    const handleCancel = () => {
        window.location.reload();

    }
    return (
        <>
            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id="content">
                    <form className="form">
                        <label className='formtitle'>Assign Cleaning tasks</label>
                        <div className="formchild">
                            <label className='labelhotel'>
                                select hotel:
                            </label>
                            <select id="hotelnaam" name="Name" required className="doublegrid" type='text'  disabled={Edit}>

                                <option>Hotel A</option>
                                <option selected='true'>Hotel B</option>
                            </select>

                        </div>
                        {/* <label className='formtitle'>
                                Select Room:
                            </label>
                        <div className="formchild">
                            
                            <div id="roomslist">
                                <div class="hotelhotellist_tableheader__eP6Jn">
                                    <p>Kamer nummer</p>
                                    <p>Type</p>
                                </div>
                                <div class="hotelhotellist_linkVarient1__2Iv7m">
                                    <p>301</p>
                                    <p>Standaard kamer</p>
                                </div>
                                <div class="hotelhotellist_linkVarient2__yqGFi">
                                    <p>401</p>
                                    <p>Standaard kamer</p>
                                </div>
                                <div class="hotelhotellist_linkVarient1__2Iv7m">
                                    <p>601</p>
                                    <p>Standaard kamer</p>
                                </div>
                                <div class="hotelhotellist_linkVarient2__yqGFi">
                                    <p>234523532</p>
                                    <p>Standaard kamer</p>
                                </div>
                                <div class="hotelhotellist_linkVarient1__2Iv7m">
                                    <p>201</p>
                                    <p>Suite</p>
                                </div>
                                <div class="hotelhotellist_linkVarient2__yqGFi">
                                    <p>101</p>
                                    <p>Deluxe kamer</p>
                                </div>
                                <div class="hotelhotellist_linkVarient1__2Iv7m">
                                    <p>501</p>
                                    <p>Deluxe kamer</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="formchild">
                            <label className='labelhotel'>
                                Kamer nr:
                            </label>
                            <select id="roomnr" name="Name" className="doublegrid" type='text' disabled={Edit}>
                                <option>301</option>
                                <option>601</option>
                                <option>310</option>
                                <option>Lobby</option>
                            </select>
                            <label className='labelhotel'>
                                Kamer type:
                            </label>
                            <input id="roomtype" name="Name" className="doublegrid" type='text' disabled>
                            </input>
                            <label className='labelhotel'>
                                Cleaning type:
                            </label>
                            <select id="cleaningtype" name="Name" className="doublegrid" type='text' disabled={Edit}>
                                <option>Vertrekker</option>
                                <option>Blijver</option>
                            </select>
                            <label className='labelhotel'>
                                Cleaning tasks:
                            </label>
                            <textarea id="cleaningtask" name="Name" className="doublegrid" type='text' value="hier komen de standaard cleaning task die aan het geselecteerde cleaning type [wordt aangepast via de room settings] hangen, dit veld is hier niet aanpasbaar" disabled>
                            </textarea>
                            <label className='labelhotel'>
                                Add Cleaning tasks:
                            </label>
                            <textarea id="cleaningtype_add" name="Name" className="doublegrid" type='text' value="hier kan men nog extra cleaning tasks toevoegen" disabled={Edit}>
                            </textarea>
                            {/* <ul>
                                <li><input type="checkbox" className='li-checkbox' />Cleaning task A</li>
                                <li><input type="checkbox" className='li-checkbox' />Cleaning Task b</li>
                            </ul> */}
                            <label className='labelhotel'>
                                Cleaner:
                            </label>
                            <select id="Cleaner" name="Name" className="doublegrid" type='text' disabled={Edit}>
                                <option>Tony Cleaner</option>
                                <option>Olaf</option>
                                <option>Edwin</option>
                            </select>
                        </div>

                        <div id="editDiv" className="formchildButton">
                            <Button id="editButton" className='' onClick={handleEdit}>Edit</Button>
                        </div>

                        <div id="saveDiv" class="two_Buttons">
                            <Button type="submit" id="saveButton" class="Savebutton btn btn-primary margin-right">Save</Button>
                            <Button type="button" id="cancelButton" class="Savebutton btn btn-primary margin-right" onClick={handleCancel} >Cancel</Button>
                        </div>
                        {/* <div id="saveDiv" className="formchildButton noBackgroundcolor">
                            <Button id="saveButton" type="submit" className=''>Edit</Button>
                        </div>
                        <div id="saveDiv" className="formchildButton noBackgroundcolor">
                            <Button id="saveButton" type="submit" className=''>Remove</Button>
                        </div> */}
                    </form>
                    <div className="form">
                        <label className='formtitle'>Cleaning tasks for today for the selected hotel </label>
                        <div id='roomslist'  className='list'>
                            <div className="tableheader Cleaningtasks"><p>Hotel</p><p>Kamer nummer</p><p>cleaner</p></div>
                            <div id="kamersarray" className='linkVarient1 Cleaningtasks'><p>Hotel B</p><p>301</p><p>Tony Cleaner</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AssignCleaningTasks