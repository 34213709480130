import { tokencall } from "./connector";
import Cookies from 'universal-cookie';

const checktoken =  async () => {
    const cookies = new Cookies();
    console.log(cookies.getAll([]))
    const token =  getCookie("logintoken",{path:"/"})
    if(token!==""){
        
        const response = await tokencall(token);
    return response

    }else{
        return false
    }
    


}


function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export { getCookie, checktoken }