
import React from "react"
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";



const extraFunctie = () =>{
    
}



const Cleaningtype = () => {

    // create navigation object if needed
    const navigate = useNavigate();



    //dop something after loading page
    useEffect(() => {

        try {
            // call on backend here if so that app does not crash and spews error code


            //simulatie van backendcall zodat code werkt
            const data = async () => {

            };

            //wachten op response van server. code binnen de brackets uitvoeren wanner de response er is (=callback function)
            data.then(response => {
                if (response === 200) {
                    //dosomething with data
                    const contentdiv = document.getElementById("content")
                } else {
                    throw new Error(response.status + ":" + response.message)
                }
            })
        } catch (error) {
            setErrorMessage(error.message)
        }


    }, []);

    return (
        <>
            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id="content" className='formwidth'>
                    <form className="form" >
                    <label className='formtitle'>
                                Cleaning type
                            </label>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Id:
                            </label>
                            <input className="normalinput" type='text'  ></input>
                            </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Name:
                            </label>
                            <input className="normalinput" type='text'  ></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Description:
                            </label>
                            <textarea name="message" className="textarea doublegrid"></textarea>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Default tasks:
                            </label>
                            <textarea name="message" className="textarea doublegrid"></textarea>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                               Duration:
                            </label>
                            <input className="normalinput" type='text'  ></input>
                        </div>
                        
                    </form>
                </div>
            </div>
        </>
    )

}

export default Cleaningtype