import React from "react"
import './css/createhotel.css';
import { getCookie } from '../dbconnector/checktoken'
import { checkout, getCheckinOrOutTime, pause, getserverurl, myData, pauseoutById, getLinkedhotels, checkinByHotelId } from "../dbconnector/connector";
import { useParams } from "react-router-dom";

import { useEffect, useState } from 'react';
import { setErrorMessage } from "../tools/errorhandling";
import { RotatingLines } from "react-loader-spinner";





const CheckInAtwork = (props) => {
    const [user, setUser] = useState({});
    const [mylinkedHotelsArray, setmylinkedHotelsArray] = useState([]);
    const [linkedhotels, setLinkedhotels] = useState({});
    let { id } = useParams();
    const development = false;
    const t = props.translation

    useEffect(() => {
        myData().then(res => {
            try {
                setUser({ ...res })

            } catch (e) {
                setErrorMessage("Error While retrieving user data")
            }

        })



    }, []);


    useEffect(() => {


        myData(id).then(res => {
            try {
                setUser({ ...res })

                const linkedhotels = getLinkedhotels(res.id)


                linkedhotels.then(res => {

                    console.log(res)
                    res.json().then(res => {
                        console.log(res)
                        setLinkedhotels(res)
                    })
                })

            } catch (e) {
                setErrorMessage("Error While retrieving user data")
            }

        })


        document.getElementById("FullScreen_LinkedHotels").style.display = "none";
        document.getElementById("checkinloadingmessage").style.display = "none";
        document.getElementById("IconCleaning").style.display = "none";
        document.getElementById("IconMyData").style.display = "none";
        document.getElementById("IconMyHoliday").style.display = "none";
        document.getElementById("IconMyContract").style.display = "none";
        const result = getCheckinOrOutTime();
        document.getElementById("checkoutlink").style.display = "none"
        document.getElementById("checkinlink").style.display = "block"
        document.getElementById("pause").style.display = "none"
        document.getElementById("pauseout").style.display = "none"

        result.then(res => {
            if (res.status === 200) {
                if (res.type === "work") {
                    if (res.inout === "in") {
                        console.log(res)
                        setTime(res.time, "Check in:", res.hotel)
                        document.getElementById("checkoutlink").style.display = "block"
                        document.getElementById("checkinlink").style.display = "none"
                        document.getElementById("pause").style.display = "block"
                        document.getElementById("pauseout").style.display = "none"
                    } else if (res.inout === "out") {
                        setTime(res.time, "Check out:")
                        document.getElementById("checkoutlink").style.display = "none"
                        document.getElementById("checkinlink").style.display = "block"
                        document.getElementById("pause").style.display = "none"
                        document.getElementById("pauseout").style.display = "none"
                    }
                } else if (res.type === "pause") {
                    if (res.inout === "in") {
                        setTime(res.time, "Pause in:", res.hotel)
                        document.getElementById("pause").style.display = "none"
                        document.getElementById("pauseout").style.display = "block"
                        document.getElementById("checkoutlink").style.display = "none"
                        document.getElementById("checkinlink").style.display = "none"

                    } else if (res.inout === "out") {
                        setTime(res.time, "Pause out:", res.hotel)
                        document.getElementById("pause").style.display = "block"
                        document.getElementById("pauseout").style.display = "none"
                        document.getElementById("checkoutlink").style.display = "none"
                        document.getElementById("checkinlink").style.display = "block"
                    }
                }

            } else {
                document.getElementById("checkoutlink").style.display = "none"
                document.getElementById("checkinlink").style.display = "block"
                document.getElementById("pause").style.display = "none"
                document.getElementById("pauseout").style.display = "none"
            }
        })

    }, []);

    const placeCheckinbar = async (res) => {

        res.then(result => {

            if (result.status === 200) {
                result.json().then(res => {
                    setTime(res.Checkintime, "Check in: ", res.hotel);
                    const daysvalid = 1
                    const d = new Date();
                    d.setTime(d.getTime() + (daysvalid * 24 * 60 * 60 * 1000));
                    let expires = "expires=" + d.toUTCString();
                    document.cookie = "checkintime= " + res.Checkintime + ";" + expires + ";path=/";
                    document.cookie = "checkOutTime= "
                    document.getElementById("checkoutlink").style.display = "block"
                    document.getElementById("checkinlink").style.display = "none"
                    document.getElementById("pause").style.display = "block"
                    document.getElementById("pauseout").style.display = "none"
                    document.getElementById("FullScreen_LinkedHotels").style.display = "none";
                    document.getElementById("mySHC_Linked_Hotels").style.display = "block";
                    document.getElementById("checkinloadingmessage").style.display = "none";

                })

            } else {
                result.json().then(body => {
                    setErrorMessage(body.statusText)
                    document.getElementById("FullScreen_LinkedHotels").style.display = "none";
                    document.getElementById("mySHC_Linked_Hotels").style.display = "block";
                    document.getElementById("checkinloadingmessage").style.display = "none";

                }

                )
            }

        })

    }

    const ShowHotelList = async () => {

            setmylinkedHotelsArray([]);
            linkedhotels.forEach(hotel => {

                setmylinkedHotelsArray(pre => [...pre, <div className="HotelSelection" onClick={() => SelectHotel(hotel.hotel_Id)} >{hotel.Name}</div>])
            });
            document.getElementById("mySHC_Linked_Hotels").style.display = "Block";
            //checktext
            document.getElementById("checktext").innerText = "Checkin-at work, please wait";
            document.getElementById("FullScreen_LinkedHotels").style.display = "Block";
    }
    const SelectHotel = async (id) => {
        // get the GPS location of the selected hotel and pass it to the CheckIn function.
        // afterwards, Clear the "mySHC_Linked_Hotels" div and hide the div.
        // store the GPS locations in the memory or database. Can be retreived for the checkout (thus avoiding the user to again select the hotel)
 //checkinloadingmessage
        document.getElementById("checkinloadingmessage").style.display = "block";
        document.getElementById("mySHC_Linked_Hotels").style.display = "none";
        const response = checkinByHotelId(id);
        placeCheckinbar(response)
       
    }
    const ShowHotelListPause = async () => {
        setmylinkedHotelsArray([]);
        linkedhotels.forEach(hotel => {
            setmylinkedHotelsArray(pre => [...pre, <div className="HotelSelection" onClick={() => SelectHotelPause(hotel.hotel_Id)} >{hotel.Name}</div>])
        });
        document.getElementById("checktext").innerText = "Checkin-at-work, please wait";
        document.getElementById("mySHC_Linked_Hotels").style.display = "Block";
        document.getElementById("FullScreen_LinkedHotels").style.display = "Block";
    }
    const SelectHotelPause = async (id) => {
        // get the GPS location of the selected hotel and pass it to the CheckIn function.
        // afterwards, Clear the "mySHC_Linked_Hotels" div and hide the div.
        // store the GPS locations in the memory or database. Can be retreived for the checkout (thus avoiding the user to again select the hotel)
        document.getElementById("checkinloadingmessage").style.display = "block";
        document.getElementById("mySHC_Linked_Hotels").style.display = "none";
        const response = pauseoutById(id);
        setPauseOut(response)
    }

    // const CheckIn = async () => {


    //     if (development === true) {
    //         const crd = { latitude: 51.05806393502347, longitude: 4.11000078949734 }
    //         const Bearer = getCookie("logintoken")
    //         const res = checkin(Bearer, crd);
    //         placeCheckinbar(res)

    //     } else {

    //         const getlocation = () => {

    //             const options = {
    //                 enableHighAccuracy: false,
    //                 timeout: 5000,
    //                 maximumAge: 0,
    //             };

    //             function success(pos) {
    //                 const Bearer = getCookie("logintoken")
    //                 const crd = pos.coords;
    //                 const result = checkin(Bearer, crd);

    //                 placeCheckinbar(result)

    //             }

    //             function error(err) {
    //                 console.log(err)
    //                 console.warn(`ERROR(${err.code}): ${err.message}`);
    //                 setErrorMessage(`ERROR(${err.code}): ${err.message}`)
    //             }



    //             navigator.geolocation.getCurrentPosition(success, error, options);

    //         }
    //         getlocation()

    //     }

    // }

    const placeCheckoutbar = async (res) => {
        res.then(result => {
            if (result.status === 200) {
                result.json().then(res => {
                    const datetimestring = new Date(res.checkouttime).toLocaleTimeString()
                    setTime(res.checkouttime, "Check out: ");
                    document.cookie = "checkintime= ";
                    document.cookie = "checkOutTime= " + datetimestring;
                    document.getElementById("checkoutlink").style.display = "none"
                    document.getElementById("checkinlink").style.display = "block"
                    document.getElementById("pause").style.display = "none"
                    document.getElementById("pauseout").style.display = "none"
                    document.getElementById("FullScreen_LinkedHotels").style.display = "none";
                    document.getElementById("mySHC_Linked_Hotels").style.display = "block";
                    document.getElementById("checkinloadingmessage").style.display = "none";
                })


            } else {
                result.json().then(res => {
                    setErrorMessage(res.statusText)
                    document.getElementById("FullScreen_LinkedHotels").style.display = "none";
                    document.getElementById("mySHC_Linked_Hotels").style.display = "block";
                    document.getElementById("checkinloadingmessage").style.display = "none";
                })
            }
        })
    }

    const CheckOut = async () => {

        document.getElementById("FullScreen_LinkedHotels").style.display = "Block";
        document.getElementById("checkinloadingmessage").style.display = "Block";
        document.getElementById("checktext").innerText = "Checkout-at work, please wait";
        document.getElementById("mySHC_Linked_Hotels").style.display = "none";
        
        const crd = { latitude: 51.05806393502347, longitude: 4.11000078949734 }
        const res = checkout("work", crd);
        placeCheckoutbar(res)

        // if (development === true) {
        //     const crd = { latitude: 51.05806393502347, longitude: 4.11000078949734 }
        //     const res = checkout("work", crd);
        //     placeCheckoutbar(res)

        // } else {


        //     const getlocation = () => {

        //         const options = {
        //             enableHighAccuracy: false,
        //             timeout: 5000,
        //             maximumAge: 0,
        //         };

        //         function success(pos) {
        //             const crd = pos.coords;
        //             const res = checkout("work", crd);
        //             placeCheckoutbar(res)
        //         }

        //         function error(err) {
        //             console.log(err)
        //             console.warn(`ERROR(${err.code}): ${err.message}`);
        //             setErrorMessage(`ERROR(${err.code}): ${err.message}`)

        //         }

        //         navigator.geolocation.getCurrentPosition(success, error, options);

        //         if (navigator.geolocation) {
        //             navigator.geolocation.getCurrentPosition((location) => {
        //             });

        //         } else {
        //             setErrorMessage("Geolocation is not supported by this browser.");

        //         }


        //     }
        //     getlocation()
        // }
    }

    const setPauseIn = async (res) => {

        res.then(res => {
            if (res.status === 200) {
                res.json().then(res => {
                    setTime(res.Checkintime, t('Pausestarted'));
                    document.getElementById("pause").style.display = "none"
                    document.getElementById("pauseout").style.display = "block"
                    document.getElementById("checkoutlink").style.display = "none"
                    document.getElementById("checkinlink").style.display = "none"
                    document.getElementById("FullScreen_LinkedHotels").style.display = "none";
                    document.getElementById("mySHC_Linked_Hotels").style.display = "block";
                    document.getElementById("checkinloadingmessage").style.display = "none";
                })

            } else {
                res.json().then(res => {
                    setErrorMessage(res.statusText)
                    document.getElementById("FullScreen_LinkedHotels").style.display = "none";
                    document.getElementById("mySHC_Linked_Hotels").style.display = "block";
                    document.getElementById("checkinloadingmessage").style.display = "none";
                })

            }

        })





    }

    const sendpauseIn = async () => {
        document.getElementById("FullScreen_LinkedHotels").style.display = "Block";
        document.getElementById("checktext").innerText = "Checkout-at-work, please wait";
        document.getElementById("checkinloadingmessage").style.display = "Block";
        document.getElementById("mySHC_Linked_Hotels").style.display = "none";
        const crd = { latitude: 51.05806393502347, longitude: 4.11000078949734 }
        const time = pause(crd);
        setPauseIn(time)

        // if (development === true) {
        //     const crd = { latitude: 51.05806393502347, longitude: 4.11000078949734 }
        //     const time = pause(crd);
        //     setPauseIn(time)
        // } else {


        //     const getlocation = () => {

        //         const options = {
        //             enableHighAccuracy: false,
        //             timeout: 5000,
        //             maximumAge: 0,
        //         };

        //         function success(pos) {
        //             const crd = pos.coords;
        //             const time = pause(crd);
        //             setPauseIn(time)
        //         }


        //         function error(err) {
        //             console.log(err)
        //             console.warn(`ERROR(${err.code}): ${err.message}`);
        //             setErrorMessage(`ERROR(${err.code}): ${err.message}`)
        //         }

        //         navigator.geolocation.getCurrentPosition(success, error, options);

        //         if (navigator.geolocation) {
        //             navigator.geolocation.getCurrentPosition((location) => {
        //             });
        //         } else {
        //             setErrorMessage("Geolocation is not supported by this browser.");
        //         }


        //     }
        //     getlocation()

        // }
    }

    const setTime = (time, type, hotel = "") => {
        console.log(hotel)
        console.log(time, type, hotel)
        const timestring = new Date(time).toLocaleTimeString()
        document.getElementById("time").innerText = hotel + " " + type + " " + timestring;


    }

    const setPauseOut = async (res) => {

        res.then(res => {
            if (res.status === 200) {
                res.json().then(res => {
                    setTime(res.checkouttime, "checked in: ", res.hotel);
                    document.getElementById("pause").style.display = "block"
                    document.getElementById("pauseout").style.display = "none"
                    document.getElementById("checkoutlink").style.display = "block"
                    document.getElementById("checkinlink").style.display = "none"
                    document.getElementById("FullScreen_LinkedHotels").style.display = "none";
                    document.getElementById("mySHC_Linked_Hotels").style.display = "block";
                    document.getElementById("checkinloadingmessage").style.display = "none";
                })
            } else {
                res.json().then(res => {
                    setErrorMessage(res.statusText)
                    document.getElementById("FullScreen_LinkedHotels").style.display = "none";
                    document.getElementById("mySHC_Linked_Hotels").style.display = "block";
                    document.getElementById("checkinloadingmessage").style.display = "none";
                })

            }

        })

    }

    // const sendpauseOut = async () => {

    //     if (development === true) {
    //         const crd = { latitude: 51.05806393502347, longitude: 4.11000078949734 }
    //         const time = pauseout(crd);
    //         setPauseOut(time)
    //     } else {


    //         const getlocation = () => {

    //             const options = {
    //                 enableHighAccuracy: false,
    //                 timeout: 5000,
    //                 maximumAge: 0,
    //             };

    //             function success(pos) {

    //                 const crd = pos.coords;

    //                 const time = pauseout(crd);
    //                 setPauseOut(time)
    //             }

    //             function error(err) {
    //                 console.log(err)
    //                 console.warn(`ERROR(${err.code}): ${err.message}`);
    //                 setErrorMessage(`ERROR(${err.code}): ${err.message}`)
    //             }

    //             navigator.geolocation.getCurrentPosition(success, error, options);

    //             if (navigator.geolocation) {
    //                 navigator.geolocation.getCurrentPosition((location) => {
    //                 });
    //             } else {
    //                 setErrorMessage("Geolocation is not supported by this browser.");
    //             }


    //         }

    //         getlocation()
    //     }
    // }




    const openContract = () => {
        window.open(getserverurl() + user.contract, "_blank")
    }
    return (
        <>
            <div className="fullscreen" id="FullScreen_LinkedHotels">
                <div  id="checkinloadingmessage" className="checkingatworkstatusmessage"><center>
                <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      visible={true} className="spinner"
    />
                   <br></br><div id="checktext">Checkin-at-Work, please wait!</div></center></div>
                <div className="LinkedHotels" id="mySHC_Linked_Hotels">
                    <div className="hoteselectionTitle" >Select your current working place</div>
                    {mylinkedHotelsArray}
                </div>
            </div>
            <div id="main" className="Screendiv_temp">

                <header>
                    <br></br>
                    <div id="errordiv" className='error'></div>
                    <div className="checkinTime">
                        <div id="timelabel"></div>
                        <div id="time"></div>
                    </div>

                </header>

                <a id="pause" type="button" className="a_in_out" onClick={sendpauseIn}>
                    <div className="checkin_out_temp">
                        <div id="pauseBox" className="box_temp" >{t('Pause')}</div>
                        <div className="divider_in_out_temp"></div>
                        <div className="picto_bottom_temp"><center><img src="img/ico_Pause.svg" alt="Pause" className="Icon_class" /></center></div>
                    </div>
                </a>
                <a id="pauseout" type="button" className="a_in_out" onClick={ShowHotelListPause}>
                    <div className="checkin_out_temp">
                        <div id="pauseBox" className="box_temp" >{t('Pausestop')}</div>
                        <div className="divider_in_out_temp"></div>
                        <div className="picto_bottom_temp"><center><img src="img/ico_Pause.svg" alt="Pause" className="Icon_class" /></center></div>
                    </div>
                </a>
                <a id="checkoutlink" type="button" className="a_in_out" onClick={CheckOut} style={{ 'display': 'none' }} >
                    <div className="checkin_out_temp">
                        <div id="checkoutbox" className="box_temp" >{t('CheckOut')}</div>
                        <div className="divider_in_out_temp"></div>
                        <div className="picto_bottom_temp"><center><img src="img/ico_CheckIn_Out.svg" alt="check_in" className="Icon_class" /></center></div>
                    </div>
                </a>
                <a id="checkinlink" type="button" className="a_in_out" onClick={ShowHotelList} disabled>
                    <div className="checkin_out_temp">
                        <div id="checkoutbox" className="box_temp" >{t('CheckIn')}</div>
                        <div className="divider_in_out_temp"></div>
                        <div className="picto_bottom_temp"><center><img src="img/ico_CheckIn_Out.svg" alt="check_in" className="Icon_class" /></center></div>
                    </div>
                </a>
                <a type="button" onClick={openContract} className="a_in_out">
                    <div className="contract_temp" >
                        <div className="box_contract_temp">{t('MyContract')}</div>
                        <div className="divider_contract_temp"></div>
                        <div className="picto_bottom_contract_temp"><center><img src="img/Ico_MyContract.svg" alt="buttonlogo" height={25} className="Icon_class" /></center></div>
                    </div>
                </a>


            </div>
            {/* <input className={'inputButton'} type="button" value={'Request Holiday'} />
            <input className={'inputButton'} type="button" value={'My Cleaning jobs'} /> */}

        </>

    )

}
export default CheckInAtwork