import './css/tabs.css';
import hotelcss from './css/hotelhotellist.module.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import React from "react"
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    sendhoteldata, getallhotels, gethotel,
    updateHotelData, getRooms, getRoomsTypes,
    getserverurl, createroom, createroomtype,
    editkamer, deleteRoom, deleteRoomtype, editkamerType, saveCleaningtask, getCleaningtasks
} from "../dbconnector/connector";
import { useParams } from "react-router-dom";
import { pdfjs } from 'react-pdf';
import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { confirmAlert } from 'react-confirm-alert';

import Button from 'react-bootstrap/Button';
import { MdEdit, MdDelete } from "react-icons/md";
import { HiOutlineChevronDown } from "react-icons/hi";
import { HiOutlineChevronUp } from "react-icons/hi";


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();


const Createhotel = () => {

    const [contract, setContract] = useState({ value: '' })
    const [hotel, setHotel] = useState('');

    const navigate = useNavigate()

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("onsubmit")
        const response = sendhoteldata({ ...hotel, Contract: contract })

        response.then(res => {
            console.log(res)
            if (res.status === 200) {
                navigate('/hotels')
            } else {
                setErrorMessage(res.status + ": " + res.statusText)
            }
        });
    }

    const handleitemchange = (e) => {
        setHotel((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
        console.log(hotel)
    }

    const handleCancel = () => {
        navigate('/hotels');
    }
    const changecheckbox = (e) => {

        setHotel((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }

        })

    }
    return (
        <>

            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id="return" className="form">
                    <Button type="button" id="cancelButton" class="Savebutton btn btn-primary margin-right" onClick={() => navigate("/hotels")} >Return to list</Button>
                </div>
                <form className="form" onSubmit={onSubmit} autocomplete="off">
                    <label className='formtitle'>
                        Hotel Details '{hotel.Name}'
                    </label>
                    <div className="formchild">
                        <label className='labelhotel'>
                            Actief:
                        </label>
                        <input id="hotelActive" type='CheckBox' className="checkbox " value={hotel.active} name="active" onChange={changecheckbox} checked={hotel.active}></input>
                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            Name:
                        </label>
                        <input id="hotelnaam" name="Name" required className="normalinput" value={hotel.Name} onChange={handleitemchange} type='text'  ></input>
                        <label className='labelhotel'>
                            Department number:
                        </label>
                        <input id="afdeling" name="nr_afd" required className="normalinput" value={hotel.nr_afd} onChange={handleitemchange} type='number' ></input>
                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            BTW:
                        </label>
                        <input id="btw" className="doublegrid" required type='text' name="btw_nummer" value={hotel.btw_nummer} onChange={handleitemchange} ></input>
                    </div>

                    <div className="formchild">
                        <label className='labelhotel'>
                            Street name:
                        </label>
                        <input id="straatnaam" type='text' required className="doublegrid" name="straatnaam" value={hotel.straatnaam} onChange={handleitemchange}  ></input>
                        <label className='labelhotel'>
                            House number:
                        </label>
                        <input id="huisnummer" type='number' required className="normalinput" name="huisnummer" value={hotel.huisnummer} onChange={handleitemchange} ></input>
                        <label className='labelhotel'>
                            Bus:
                        </label>
                        <input id="bus" type='text' className="normalinput" name="bus" value={hotel.bus} onChange={handleitemchange} ></input>

                        <label className='labelhotel'>
                            Zipcode:
                        </label>
                        <input id="zipcode" type='number' required className="normalinput" name="zipcode" value={hotel.zipcode} onChange={handleitemchange} ></input>
                        <label className='labelhotel'>
                            City:
                        </label>
                        <input id="gemeente" type='text' required className="normalinput" name="gemeente" value={hotel.gemeente} onChange={handleitemchange} ></input>
                        <label className='labelhotel'>
                            Country:
                        </label>
                        <input id="land" type='text' required className="normalinput" name="land" value={hotel.land} onChange={handleitemchange} ></input>

                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            GPS location:
                        </label>
                        <input id="gpslocatie" type='text' className="doublegrid" name="gps_locatie" value={hotel.gps_locatie} onChange={handleitemchange} ></input>
                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            Contractual reference:
                        </label>
                        <input id="contractrelationNumber" type='text' className="doublegrid" name="contractrelationNumber" value={hotel.contractrelationNumber} onChange={handleitemchange} disabled></input>
                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            Contact:
                        </label>
                        <input id="contactperson" type='text' className="doublegrid" name="contactperson" value={hotel.contactperson} onChange={handleitemchange} ></input>
                        <label className='labelhotel'>
                            Contact number:
                        </label>
                        <input id="contactnumber" type='tel' className="doublegrid" name="contactnumber" value={hotel.contactnumber} onChange={handleitemchange} ></input>
                    </div>
                    <div id="uploaddiv" className="formchild">
                        <label className='labelhotel'>
                            Upload Contract:
                        </label>
                        <input id="uploadContract" className="normalinput" type='file' accept='.pdf' onChange={(e) => setContract({ value: e.target.files[0] })}></input>
                    </div>
                    <div className="formchild">
                        <label className='labelhotel'>
                            Note:
                        </label>
                        <textarea id="Note" type='textarea' className="doublegrid" name="note" value={hotel.note} onChange={handleitemchange}></textarea>
                    </div>
                    <div id="saveDiv" className="two_Buttons">
                        <Button id="saveButton" type="submit" className='Savebutton'>Save</Button>
                        <Button id="cancelButton" className='Savebutton' onClick={handleCancel}>Cancel</Button>
                    </div>
                </form>


            </div>
        </>

    )
}

const Viewhotels = () => {

    const [hotels, sethotels] = useState([]);

    const navigate = useNavigate();

    console.log("Createhotelslist")


    useEffect(() => {

        try {
            const hotels = getallhotels();
            hotels.then((hotellist) => {
                console.log(hotellist)
                if (hotellist.status === 200) {
                    sethotels(hotellist.items)
                    Createhotelslist(hotellist.items, navigate);

                    const zoekbalk = document.getElementById("Zoekbalk");
                    const zoekbalk2 = document.getElementById("Zoekbalk2");


                    zoekbalk.addEventListener("input", function (e) {
                        const zoekbalk2 = document.getElementById("Zoekbalk2");
                        console.log("filter")
                        console.log(e.target.value.toLowerCase())
                        console.log(hotels)
                        const filteredhotels = hotellist.items.filter(element => (element.Name.toLowerCase().includes(e.target.value.toLowerCase())))
                        Createhotelslist(filteredhotels, navigate)
                    })




                    zoekbalk2.addEventListener("input", function (e) {
                        const zoekbalk = document.getElementById("Zoekbalk");
                        const filteredhotels = hotellist.items.filter(element => (element.nr_afd.toString().includes(e.target.value)))
                        Createhotelslist(filteredhotels, navigate)
                    })

                } else {
                    setErrorMessage("Error while retrieving hotels")
                }

            })

        } catch (error) {
            setErrorMessage("Error while retrieving hotels")
        }


    }, []);

    const Createhotelslist = (hotellist, navigate) => {


        let listForHotels = document.getElementById("hotelsList");
        if (listForHotels === null) {
            listForHotels = document.createElement("div");

            listForHotels.id = "hotelsList";

            const upperdiv = document.getElementById("main");




            upperdiv.appendChild(listForHotels);

        } else {
            listForHotels.innerHTML = ""


        }


        /* #region  creating headings */
        const employediv = document.createElement("div")
        listForHotels.appendChild(employediv)

        const HOTEL_ID_div = document.createElement("p")
        HOTEL_ID_div.innerText = "Dep. nummer"
        employediv.appendChild(HOTEL_ID_div)
        const EMP_ID_div = document.createElement("p")
        EMP_ID_div.innerText = "Name"
        employediv.appendChild(EMP_ID_div)

        const name = document.createElement("p")
        employediv.appendChild(name)
        name.innerText = "Address"
        employediv.className = hotelcss.tableheader;

        /* #endregion */

        hotellist.forEach((hotel, index) => {

            const hotellink = document.createElement("div");
            //addname element to div
            const IdP = document.createElement("p");
            IdP.className = hotelcss.textalignright;
            IdP.innerText = hotel.nr_afd;
            hotellink.appendChild(IdP);
            const nameP = document.createElement("p");
            nameP.innerText = hotel.Name;
            hotellink.appendChild(nameP);
            const description = document.createElement("p");
            description.innerText = hotel.straatnaam + " " + hotel.huisnummer + ", " + hotel.zipcode + " " + hotel.gemeente;
            hotellink.appendChild(description);





            //dinamicly add classname
            if (index % 2 === 0) {
                hotellink.className = hotelcss.linkVarient1
            } else {
                hotellink.className = hotelcss.linkVarient2
            }
            hotellink.addEventListener('click', function (e) {
                navigate("/hotels/" + hotel.Hotel_ID);
            });

            listForHotels.appendChild(hotellink)
        });
    }


    return (
        <>
            <div id="main">
                <header>
                    <div id="errordiv" className='error'>
                    </div>
                </header>
                <div id="newuser" className="form">
                    <Button id="addhotelsbutton" onClick={() => (navigate("/createhotel"))} > Create hotel</Button>
                </div>
                <div id="filters" className="form" >
                    <label className='formtitleContentBox'>filter instellingen</label>
                    <br></br>
                    <div className="contentbox">
                        <div className="formElement">
                            <label className='labelcolor'>
                                Hotel Naam:
                            </label>
                            <input type="text" id="Zoekbalk" placeholder="Search"></input>
                        </div>
                        <div className="formElement">
                            <label className='labelcolor'>
                                Hotel Nr:
                            </label>
                            <input type="text" id="Zoekbalk2" placeholder="Search"></input>
                        </div>
                    </div>

                    <div id="hotelsList" className="list">
                    </div>
                </div>
            </div>

        </>

    )
}




const HotelView = () => {
    const navigate = useNavigate()
    const [contract, setContract] = useState({ value: '' })
    const [hotel, setHotel] = useState({});
    const [kamers, setKamers] = useState({});
    const [kamerTypes, setKamerTypes] = useState({});
    const [addkamer, setaddkamer] = useState({});
    const [kamertypesarray, setkamertypesarray] = useState([]);
    const [kamersarray, setkamersarray] = useState([]);
    const [cleaningtaskarray, setcleaningtaskarray] = useState([]);
    const [CleaningTasks, setCleaningTasks] = useState({});
    const [toUpdate, setToUpdate] = useState({});

    let { id } = useParams();
    // const navigate = useNavigate()





    const addKamerfield = () => {


        console.log("in kamer field")
        let list = document.getElementById("roomslist");

        const kamerdiv = document.createElement("div");
        //addname element to div
        const kamernummer = document.createElement("input");
        kamernummer.onchange = (e) => setaddkamer({ ...addkamer, kamernummer: e.target.value })
        kamernummer.className = "inputTable";
        kamernummer.type = "number";
        kamernummer.id = "kamernummerinput";
        kamernummer.focus();
        kamerdiv.appendChild(kamernummer);

        const Type = document.createElement("select");
        Type.onchange = (e) => setaddkamer({ ...addkamer, Type: e.target.value })
        kamerdiv.appendChild(Type);

        Type.id = "kamertypeid"

        kamerdiv.className = hotelcss.linkVarientInput
        const kameraaray = document.getElementById("kamersarray")

        kameraaray.insertBefore(kamerdiv, kameraaray.firstChild);


        for (const type of kamerTypes) {
            const option = document.createElement("option")
            option.innerText = type.Naam;
            option.value = type.id;
            Type.appendChild(option)

        }

        const addbut = document.createElement("Button");
        addbut.innerText = "Add";
        addbut.className = "AddButtonTable";
        kamerdiv.id = "inputdiv"
        kamerdiv.appendChild(addbut);
        addbut.onclick = handleaddkamer


    }




    useEffect(() => {
        gethotel(id).then(result => {
            console.log(result)

            if (result.status === 200) {
                setHotel(result.hotel);
                refreshkamers()
                refreshkamertypes()
                refreshcleaningtasks()
            } else {
                setErrorMessage("could not retrieve Hotel data")
            }
        });

        document.getElementById("viewcontractButton").style.display = "grid";
        document.getElementById("uploaddiv").style.display = "none";

        document.getElementById("editDiv").style.display = "grid";
        document.getElementById("saveDiv").style.display = "none";

    }, []);

    const refreshkamers = () => {
        console.log("in get objects")
        getRooms(hotel.Hotel_ID).then(res => {
            if (res.status === 200) {
                setKamers(res.items)
                kamerstab(0, res.items);
            } else {
                setErrorMessage("failed to retrieve rooms")
            }

        })
    }
    const refreshkamertypes = () => {

        getRoomsTypes(hotel.Hotel_ID).then(res => {
            if (res.status === 200) {
                setKamerTypes(res.items)
            } else {
                setErrorMessage("failed to retrieve rooms types")
            }

        })
    }

    const refreshcleaningtasks = () => {
        if(hotel.Hotel_ID){
            getCleaningtasks(hotel.Hotel_ID).then(res => {
                if (res.status === 200) {
                    setCleaningTasks(res.items)
                } else {
                    setErrorMessage(res.statusText)
                }
    
            })
        }

       
    }

    useEffect(() => {

        if (hotel.Hotel_ID) {
            getRooms(hotel.Hotel_ID).then(res => {
                if (res.status === 200) {
                    setKamers(res.items)
                } else {
                    setErrorMessage("failed to retrieve rooms")
                }


            })

            getRoomsTypes(hotel.Hotel_ID).then(res => {

                if (res.status === 200) {
                    console.log('kamertypes:', res.items)
                    setKamerTypes(res.items)
                } else {
                    setErrorMessage("failed to retrieve roomtypes")
                }

            })
            getCleaningtasks(hotel.Hotel_ID).then(res => {

                if (res.status === 200) {

                    res.json().then(body=>{
                        console.log('cleaningtasks:', body.items)
                        setCleaningTasks(body.items)
                    })
                    
                } else {
                    setErrorMessage(res.statusText)
                }

            })


        }

    }, [hotel]);

    const kamerstab = (index, items = false) => {
        if (index === 0) {
            if (kamers[0]) {
                setTimeout(() => {
                    console.log("Delayed for 1 second.");
                    if (items !== false) {
                        console.log(items)
                        //Createkamerslist(items, "roomslist")

                    } else {
                        //Createkamerslist(kamers, "roomslist")
                    }

                }, "10");

            }

        }
        if (index === 1) {
            if (kamerTypes[0]) {
                setTimeout(() => {
                    if (items !== false) {
                        console.log(items)
                        //Createkamertypeslist(items, "typeslist")
                    } else {
                        // Createkamertypeslist(kamerTypes, "typeslist")
                    }
                }, "10");

            }

        }

    }
    const handleitemchange = (e) => {
        setHotel((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }

        })

        setToUpdate((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }

        })
        console.log(hotel)
    }
    const handleEdit = () => {
        document.getElementById("hotelnaam").disabled = false;
        document.getElementById("contractrelationNumber").disabled = false;
        document.getElementById("bus").disabled = false;
        document.getElementById("afdeling").disabled = false;
        document.getElementById("btw").disabled = false;
        document.getElementById("straatnaam").disabled = false;
        document.getElementById("huisnummer").disabled = false;
        document.getElementById("zipcode").disabled = false;
        document.getElementById("gemeente").disabled = false;
        document.getElementById("land").disabled = false;
        document.getElementById("gpslocatie").disabled = false;
        document.getElementById("Note").disabled = false;
        document.getElementById("saveButton").innerText = "Save";
        document.getElementById("contractdiv").style.display = "none";
        document.getElementById("uploaddiv").style.display = "grid";
        document.getElementById("editDiv").style.display = "none";
        document.getElementById("saveDiv").style.display = "grid";
        document.getElementById("contactnumber").disabled = false;
        document.getElementById("contactperson").disabled = false;
        document.getElementById("hotelActive").disabled = false;

    }
    const handleCancel = () => {
        window.location.reload();

    }


    const handleSave = (e) => {
        e.preventDefault();


        if (contract.value.name) {
            updateHotelData({ ...toUpdate, Contract: contract }, hotel.Hotel_ID).then(() => {
                console.log("/hotels/" + hotel.Hotel_ID)
                window.location.reload();

            })

        } else {
            updateHotelData({ ...toUpdate }, hotel.Hotel_ID).then(() => {
                console.log('/hotels/' + hotel.Hotel_ID)
                window.location.reload();
            })

        }

    }

    const openContract = () => {
        window.open(getserverurl() + hotel.Contract, "_blank")
    }

    const handleaddkamer = () => {
        const roomnumer = document.getElementById("kamernummerinput").value
        const kamertypid = document.getElementById("kamertypeid").value
        const hotelid = hotel.Hotel_ID;
        createroom(roomnumer, kamertypid, hotelid).then((res) => {
            refreshkamers();

            document.getElementById("kamersarray").removeChild(document.getElementById("inputdiv"));
        })

    }

    const handleaddkamerType = () => {
        const Kamertypename = document.getElementById("kamernummerinput").value
        const hotelid = hotel.Hotel_ID;

        createroomtype(Kamertypename, hotelid).then((res) => {
            if (res.status === 200) {
                res.json().then(body => {
                    setKamerTypes(pre => body)
                    document.getElementById("kamerstypeaaray").removeChild(document.getElementById("toberemoved"));
                })
            } else {
                setErrorMessage(res.statusText)
            }

        })
    }


    const handleaddCleaningTask = () => {
        const taskDescription = document.getElementById("cleaningtaskdescription").value
        const hotel_Id = hotel.Hotel_ID;
        const taskDuration = document.getElementById("cleaningtaskduration").value

        console.log("cleaningtaskdescription = " + taskDescription)
        console.log("hotelid = " + hotel_Id)
        console.log("cleaningtaskduration = " + taskDuration)

        const promise = saveCleaningtask({ taskDescription, hotel_Id, taskDuration })

        promise.then((response) => {
            if (response.status === 200) {
                response.json().then((body)=>{
                    console.log(body.items);
                })
            }
            else {
                setErrorMessage(response.statusText);
            }
        })

    }

    const addKamertypefield = () => {

        //Createkamertypeslist(kamers, "typeslist")

        let list = document.getElementById("typeslist");

        const kamerdiv = document.createElement("div");
        //addname element to div
        const kamernummer = document.createElement("input");
        kamernummer.className = "RTlinkVarientInput";
        kamernummer.type = "text";
        kamernummer.id = "kamernummerinput";

        kamerdiv.appendChild(kamernummer);


        kamerdiv.className = hotelcss.RTlinkVarientInput
        const kamerTypeAray = document.getElementById("kamerstypeaaray")

        kamerTypeAray.insertBefore(kamerdiv, kamerTypeAray.firstChild);


        const addbut = document.createElement("Button");
        addbut.innerText = "Add";
        addbut.className = "AddButtonTable";
        kamerdiv.appendChild(addbut);
        addbut.onclick = () => handleaddkamerType()
        kamerdiv.id = "toberemoved"
        kamernummer.focus()


    }


    const addCleaningtaskfield = () => {



        const cleaningdiv = document.createElement("div");
        //addname element to div
        const cleaningtaskdescription = document.createElement("input");
        cleaningtaskdescription.className = hotelcss.CTinput;
        cleaningtaskdescription.type = "text";
        cleaningtaskdescription.id = "cleaningtaskdescription";
        cleaningdiv.appendChild(cleaningtaskdescription);


        const cleaningtaskduration = document.createElement("input");
        cleaningtaskduration.className = hotelcss.CTinput;
        cleaningtaskduration.type = "number";
        cleaningtaskduration.id = "cleaningtaskduration";
        cleaningdiv.appendChild(cleaningtaskduration);
        cleaningdiv.className = hotelcss.CTlinkVarientInput
        const cleaningtaskarray = document.getElementById("cleaningtaskarray")

        cleaningtaskarray.insertBefore(cleaningdiv, cleaningtaskarray.firstChild);

        const addbut = document.createElement("Button");
        addbut.innerText = "Add";
        addbut.className = "AddButtonTable";

        cleaningdiv.appendChild(addbut);
        addbut.onclick = () => handleaddCleaningTask()
        cleaningdiv.id = "toberemoved"
        cleaningtaskdescription.focus()


    }


    useEffect(() => {
        if (CleaningTasks.length > 0) {
            console.log("incleaningTasks array")
            console.log(CleaningTasks)
            const types = CleaningTasks
            const array = []
            setcleaningtaskarray(pre => []);
            console.log(cleaningtaskarray)

            types.forEach((item, index) => {
                console.log(item)
                setkamertypesarray(pre => [...pre,
                <div id={item.id} className={index % 2 === 0 ? hotelcss.RTlinkVarient1 : hotelcss.RTlinkVarient2} >
                    <p>{item.taskDescription}</p>
                    <p>{item.taskDuration}</p>
                    <div className='crudbuttons'>
                        <MdEdit onClick={() => handleKamerTypeEditClick(item.id, item, index)} />
                        <MdDelete onClick={() => handleKamerTypeDeleteClick(item.Naam)} />
                    </div>
                </div>
                ])
            })
        }

    }, [kamerTypes]);





    useEffect(() => {
        if (kamerTypes.length > 0) {
            console.log("inkamertypes array")
            console.log(kamerTypes)
            const types = kamerTypes
            const array = []
            setkamertypesarray(pre => []);
            console.log(kamertypesarray)

            types.forEach((item, index) => {
                console.log(item)
                setkamertypesarray(pre => [...pre,
                <div id={item.id} className={index % 2 === 0 ? hotelcss.RTlinkVarient1 : hotelcss.RTlinkVarient2} >
                    <p>{item.Naam}</p>
                    <div className='crudbuttons'>
                        <MdEdit onClick={() => handleKamerTypeEditClick(item.id, item, index)} />
                        <MdDelete onClick={() => handleKamerTypeDeleteClick(item.Naam)} />
                    </div>
                </div>
                ])
            })
        }

    }, [kamerTypes]);

    useEffect(() => {
        document.getElementById('closeformchevronDown').style.display = 'none';
        if (kamers.length > 0) {
            setkamersarray([]);
            kamers.forEach((item, index) => {
                setkamersarray(pre => [...pre,
                <div id={item.kamerNummer} className={index % 2 === 0 ? hotelcss.RoomlinkVarient1 : hotelcss.RoomlinkVarient2} >
                    <p>{item.kamerNummer}</p>
                    <p>{item.Naam}</p>
                    <div className='crudbuttons'>
                        <MdEdit onClick={() => handleKamerEditClick(item.kamerNummer, item, index)} />
                        <MdDelete onClick={() => handleKamerDeleteClick(item.kamerNummer)} />
                    </div>
                </div>
                ])
            })
        }

    }, [kamers]);

    const handleKamerEditClick = async (id) => {

        getRoomsTypes(hotel.Hotel_ID).then(res => {

            if (res.status === 200) {
                setKamerTypes(res.items)
                const kamerdiv = document.getElementById(id)
                kamerdiv.innerHTML = ""
                //addname element to div
                const kamernummer = document.createElement("p");
                kamernummer.id = "kamernummerinput"
                kamernummer.innerText = id
                kamerdiv.appendChild(kamernummer);

                const Type = document.createElement("select");
                Type.onchange = (e) => setaddkamer({ ...addkamer, Type: e.target.value })
                kamerdiv.appendChild(Type);
                Type.id = "kamertypeid"

                kamerdiv.className = hotelcss.linkVarientInput



                for (const type of res.items) {
                    const option = document.createElement("option")
                    option.innerText = type.Naam;
                    option.value = type.id;
                    Type.appendChild(option)


                }

                const savebutton = document.createElement("Button");
                savebutton.innerText = "Save";
                savebutton.className = "AddButtonTable";
                kamerdiv.id = "inputdiv"
                kamerdiv.appendChild(savebutton);
                savebutton.onclick = () => handleEditkamerSave(id)

            } else {
                setErrorMessage("failed to retrieve roomtypes")
            }

        })







    }

    const handleEditkamerSave = (id) => {
        const type = document.getElementById("kamertypeid").value
        console.log(type, id)

        const response = editkamer(id, type, hotel.Hotel_ID);
        response.then(res => {
            if (res.status === 200) {
                res.json().then(body => {
                    setkamersarray(pre => [])
                    setKamers(body);

                })
            } else {
                setErrorMessage(res.statusText)
            }
        })


    }

    const handleKamerDeleteClick = (id) => {
        const options = {
            title: 'Delete?',
            message: 'Wil jij kamer "' + id + '" deleten?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteRoom(id, hotel.Hotel_ID)
                        window.location.reload()
                    }
                },
                {
                    label: 'No',
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options)

    }

    const handleKamerTypeEditClick = (id, item) => {

        console.log("inkameredit")


        const kamerdiv = document.getElementById(id)
        kamerdiv.innerHTML = ""
        //addname element to div
        const type = document.createElement("input");
        type.id = "kamername"
        type.value = item.Naam
        kamerdiv.appendChild(type);


        kamerdiv.className = hotelcss.RTlinkVarientInput





        const savebutton = document.createElement("Button");
        savebutton.innerText = "Save";
        savebutton.className = "AddButtonTable";
        kamerdiv.id = "toberemoved"
        kamerdiv.appendChild(savebutton);
        savebutton.onclick = () => handleEditkamerTypeSave(id)


    }

    const handleEditkamerTypeSave = (id) => {
        const name = document.getElementById("kamername").value
        console.log(name, id)

        const response = editkamerType(id, name, hotel.Hotel_ID);
        response.then(res => {
            if (res.status === 200) {
                res.json().then(body => {
                    setkamertypesarray(pre => [])
                    setKamerTypes(body);
                    refreshkamers()

                })
            } else {
                setErrorMessage(res.statusText)
            }
        })

    }
    const changecheckbox = (e) => {
        setHotel((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }

        })

        setToUpdate((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }

        })

    }


    const handleKamerTypeDeleteClick = (naam) => {
        const options = {
            title: 'Delete?',
            message: 'Wil jij kamerType "' + naam + '" deleten?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        //deleteRoomtype(id)
                        window.location.reload()
                    }
                },
                {
                    label: 'No',
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options)

    }
    const CloseForm = () => {
        document.getElementById('formcontent').style.display = 'none';
        document.getElementById('closeformchevronDown').style.display = 'block';
        document.getElementById('closeformchevronUp').style.display = 'none';
    }
    const OpenForm = () => {
        document.getElementById('formcontent').style.display = 'block';
        document.getElementById('closeformchevronDown').style.display = 'none';
        document.getElementById('closeformchevronUp').style.display = 'block';
    }
    return (
        <>

            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id="return" className="form">
                    <Button type="button" id="cancelButton" class="Savebutton btn btn-primary margin-right" onClick={() => navigate("/hotels")} >Return to list</Button>
                </div>


                <form className="form" onSubmit={handleSave} autocomplete="off">
                    <div className='formCollapse'>
                        <label className='formtitle'>
                            Hotel Details '{hotel.Name}'
                        </label>
                        <HiOutlineChevronDown onClick={OpenForm} id='closeformchevronDown' />
                        <HiOutlineChevronUp onClick={CloseForm} id='closeformchevronUp' />
                    </div>
                    <div id="formcontent">
                        <div className="formchild" id='FPactive'>
                            <label className='labelhotel'>
                                Actief:
                            </label>
                            <input id="hotelActive" type='CheckBox' className="checkbox " value={hotel.active} name="active" onChange={changecheckbox} checked={hotel.active} disabled></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Name:
                            </label>
                            <input id="hotelnaam" name="Name" required className="normalinput" value={hotel.Name} onChange={handleitemchange} type='text' disabled ></input>
                            <label className='labelhotel'>
                                Department number:
                            </label>
                            <input id="afdeling" name="nr_afd" required className="normalinput" value={hotel.nr_afd} onChange={handleitemchange} type='number' disabled></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                BTW:
                            </label>
                            <input id="btw" className="doublegrid" required type='text' name="btw_nummer" value={hotel.btw_nummer} onChange={handleitemchange} disabled></input>
                        </div>

                        <div className="formchild">
                            <label className='labelhotel'>
                                Street name:
                            </label>
                            <input id="straatnaam" type='text' required className="doublegrid" name="straatnaam" value={hotel.straatnaam} onChange={handleitemchange} disabled ></input>
                            <label className='labelhotel'>
                                House number:
                            </label>
                            <input id="huisnummer" type='number' required className="normalinput" name="huisnummer" value={hotel.huisnummer} onChange={handleitemchange} disabled></input>
                            <label className='labelhotel'>
                                Bus:
                            </label>
                            <input id="bus" type='text' className="normalinput" name="bus" value={hotel.bus} onChange={handleitemchange} disabled></input>
                            <label className='labelhotel'>
                                Zipcode:
                            </label>
                            <input id="zipcode" type='number' required className="normalinput" name="zipcode" value={hotel.zipcode} onChange={handleitemchange} disabled></input>
                            <label className='labelhotel'>
                                City:
                            </label>
                            <input id="gemeente" type='text' required className="normalinput" name="gemeente" value={hotel.gemeente} onChange={handleitemchange} disabled></input>
                            <label className='labelhotel'>
                                Country:
                            </label>
                            <input id="land" type='text' required className="normalinput" name="land" value={hotel.land} onChange={handleitemchange} disabled></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                GPS location:
                            </label>
                            <input id="gpslocatie" type='text' className="doublegrid" name="gps_locatie" value={hotel.gps_locatie} onChange={handleitemchange} disabled></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Contractual reference:
                            </label>
                            <input id="contractrelationNumber" type='text' className="doublegrid" name="contractrelationNumber" value={hotel.contractrelationNumber} onChange={handleitemchange} disabled></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Contact:
                            </label>
                            <input id="contactperson" type='text' className="doublegrid" name="contactperson" value={hotel.contactperson} onChange={handleitemchange} disabled></input>
                            <label className='labelhotel'>
                                Contact number:
                            </label>
                            <input id="contactnumber" type='tel' className="doublegrid" name="contactnumber" value={hotel.contactnumber} onChange={handleitemchange} disabled></input>
                        </div>
                        <div id="uploaddiv" className="formchild">
                            <label className='labelhotel'>
                                Upload Contract:
                            </label>
                            <input id="uploadContract" className="normalinput" type='file' accept='.pdf' onChange={(e) => setContract({ value: e.target.files[0] })}></input>
                        </div>
                        <div className="formchild">
                            <label className='labelhotel'>
                                Note:
                            </label>
                            <textarea id="Note" type='textarea' className="doublegrid" name="note" value={hotel.note} onChange={handleitemchange} disabled></textarea>
                        </div>

                        <div className="formchildButton" id="contractdiv">

                            <Button type="button" id="viewcontractButton" className='Savebutton' onClick={openContract} >View contract</Button>
                        </div>
                        <div id="editDiv" className="formchildButton">
                            <Button id="editButton" className='' onClick={handleEdit}>Edit</Button>
                        </div>
                        <div id="saveDiv" className="two_Buttons">
                            <Button id="saveButton" type="submit" className='Savebutton'>Save</Button>
                            <Button id="cancelButton" className='Savebutton' onClick={handleCancel}>Cancel</Button>
                        </div>
                    </div>
                </form>

                <div className='form'>
                    <Tabs className="HotelTabs" onSelect={(index) => kamerstab(index)}>
                        <TabList >
                            <Tab>Kamers</Tab>
                            <Tab>Kamer types</Tab>
                            <Tab>Cleaning types</Tab>
                            <Tab>Cleaning tasks</Tab>
                        </TabList>
                        <TabPanel>
                            <div className='tabpanel'>
                                <Button id="addkamerButton" className='Savebutton' onClick={() => addKamerfield()}>Add room</Button>
                                <div id='roomslist' className='list'>
                                    <div className={hotelcss.roomtableheader}><p>Kamer nummer</p><p>Type</p></div>
                                    <div id="kamersarray" >{kamersarray}</div>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel >
                            <div className='tabpanel'>
                                <Button id="addkamertypes" className='Savebutton' onClick={addKamertypefield}>Add room type</Button>
                                <div id='typeslist' className='list'>
                                    <div className={hotelcss.roomtypestableheader}><p>Type</p></div>
                                    <div id="kamerstypeaaray" >
                                        {kamertypesarray}
                                    </div>

                                </div>


                            </div>
                        </TabPanel>
                        <TabPanel >
                            <div className='tabpanel'>
                                <Button id="saveButton" className='Savebutton' href='/Cleaningtype'>Add Cleaning type</Button>

                                <div id='cleaningtypeslist' className='list'>
                                    <div className={hotelcss.roomtypestableheader}><p>Cleaning type</p></div>
                                    <div id="cleaningtypeaaray" >
                                        {/* {kamertypesarray} */}
                                    </div>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel >
                            <div className='tabpanel'>
                                <Button id="saveButton" className='Savebutton' onClick={addCleaningtaskfield}>Add Cleaning task</Button>
                                <div id='cleaningtaskslist' className='list'>
                                    <div className={hotelcss.cleaningtasktableheader}><p>Description</p><p>Duration [minutes]</p></div>
                                    <div id="cleaningtaskarray" >
                                        {/* {kamertypesarray} */}
                                    </div>

                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export { Createhotel, Viewhotels, HotelView }