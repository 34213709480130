
import React from "react"
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";
import hotelcss from './css/hotelhotellist.module.css';
import { myData, updateEmployeedata, uploadEmployeeContract, getserverurl, getallhotels, saveLinkedhotels, getLinkedhotels } from "../dbconnector/connector";
import { useParams } from "react-router-dom";
import { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Button from 'react-bootstrap/Button';

const EmplopyeePage = () => {
    const [user, setUser] = useState({});
    const [linkedhotels, setLinkedHotels] = useState({});
    const [contract, setContract] = useState({});
    const [Edit, setEdit] = useState(true);
    const [hotels, sethotels] = useState([]);
    const [hotelsarray, setHotelArray] = useState([]);

    let { id } = useParams();
    const navigate = useNavigate()


    useEffect(() => {
        myData(id).then(res => {
            try {
                setUser({ ...res })
                setLinkedHotels({})
                const linkedhotels = getLinkedhotels(res.id)

                linkedhotels.then(res => {
                    console.log(res)
                    res.json().then(res => {

                        console.log(res)

                        res.forEach(item => {
                            setLinkedHotels(pre => {
                                return {
                                    ...pre, [item.hotel_Id]: true
                                }
                            })
                        });

                        const hotels = getallhotels();
                        hotels.then((hotellist) => {
                            console.log(hotellist)
                            if (hotellist.status === 200) {
                                sethotels(hotellist.items)
                            } else {
                                setErrorMessage("Error while retrieving hotels")
                            }
            
                        })

                    })
                })

            } catch (e) {
                setErrorMessage("Error While retrieving user data")
            }
        })
        document.getElementById("editDiv").style.display = "grid";
        document.getElementById("saveDiv").style.display = "none";
    }, []);



    const onSubmit = (e) => {
        e.preventDefault()

        const response = updateEmployeedata({ ...user, geboortedatum: new Date(user.geboortedatum).toISOString().slice(0, 19).replace('T', ' '), in_dienst: new Date(user.in_dienst).toISOString().slice(0, 19).replace('T', ' '), uit_dienst: new Date(user.uit_dienst).toISOString().slice(0, 19).replace('T', ' ') })
        const savelinkedhotels = saveLinkedhotels(linkedhotels, user.id)
        response.then(res => {
            if (res.status === 200) {
                res.json().then(body => {
                    console.log(body)

                    console.log(contract);
                    console.log((contract.name))
                    if (contract.name) {
                        const contractresponse = uploadEmployeeContract(body[0].id, contract);
                        contractresponse.then(res => {
                            if (res.status === 200) {
                                savelinkedhotels.then(res => {
                                    if (res.status === 200) {
                                        window.location.reload()
                                    } else {
                                        setErrorMessage(res.statusText)
                                    }
                                })

                            } else {
                                setErrorMessage(res.statusText)
                                savelinkedhotels.then(res => {
                                    if (res.status === 200) {
                                        window.location.reload()
                                    } else {
                                        setErrorMessage(res.statusText)
                                    }
                                })
                            }
                        })

                    } else {
                        savelinkedhotels.then(res => {
                            if (res.status === 200) {
                                window.location.reload()
                            } else {
                                setErrorMessage(res.statusText)
                            }
                        })
                    }

                })


            } else {
                setErrorMessage(res.statusText)
            }
        })
    }

    const changevalue = (e) => {

        setUser((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }

        })

    }
    const changecheckbox = (e) => {

        setUser((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }

        })

    }
    const changehotelcheckbox = (e) => {

        console.log(linkedhotels)
//alert(e.target.checked)
        setLinkedHotels((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.checked
            }


        })
       buildhotelsArray(hotels,false)
        

    }

    // const DeactivateUser = () => {
    //     console.log(user.actief)
    //     console.log(document.getElementById("userActive").checked ? false : true)
    //     document.getElementById("userActive").checked = document.getElementById("userActive").checked ? false : true;
    // }


    const openContract = () => {
        window.open(getserverurl() + user.contract, "_blank")
    }
    const navigateback = () => {
        navigate('/employees')
    }
    const handleEdit = () => {

        setEdit(false)

        buildhotelsArray(hotels, false)

        document.getElementById("editDiv").style.display = "none";
        document.getElementById("saveDiv").style.display = "grid";
    }
    const handleCancel = () => {
        window.location.reload();

    }

    useEffect(() => {

        buildhotelsArray(hotels, true)

    }, [hotels]);
    useEffect(() => {

        if (hotels.length > 0) {
            setHotelArray([]);
            hotels.forEach((item, index) => {
                console.log(linkedhotels[item.Hotel_ID])
                setHotelArray(pre => [...pre,
                <div id={item.Hotel_ID} className={index % 2 === 0 ? hotelcss.RoomlinkVarient1 : hotelcss.RoomlinkVarient2} >
                    <p>{item.Hotel_ID}</p>
                    <p>{item.Name}</p>
                    <input id={item.Hotel_ID} type='CheckBox' className="checkbox" name={item.Hotel_ID} checked={linkedhotels[item.Hotel_ID]} onChange={changehotelcheckbox} disabled={Edit}></input>
                </div>
                ])
            })
        }

    }, [linkedhotels]);


    const buildhotelsArray = (hotels, active) => {
        if (hotels.length > 0) {
            setHotelArray([]);
            hotels.forEach((item, index) => {
                console.log(linkedhotels[item.Hotel_ID])
                setHotelArray(pre => [...pre,
                <div id={item.Hotel_ID} className={index % 2 === 0 ? hotelcss.RoomlinkVarient1 : hotelcss.RoomlinkVarient2} >
                    <p>{item.Hotel_ID}</p>
                    <p>{item.Name}</p>
                    <input id={item.Hotel_ID} type='CheckBox' className="checkbox" name={item.Hotel_ID} checked={linkedhotels[item.Hotel_ID]} onChange={changehotelcheckbox} disabled={active}></input>
                </div>
                ])
            })
        }
    }

    return (


        <>
            <div id="main" className="main">
                <header><div id="errordiv" className='error'></div><div className="checkinTime"><div id="timelabel" ></div><div id="time"></div></div></header>
                <div id="return" className="form">
                    <Button type="button" id="cancelButton" class="Savebutton btn btn-primary margin-right" onClick={navigateback} >Return to list</Button>
                </div>
                <div>
                    <form className="form" onSubmit={onSubmit} autoComplete="off" >
                        <label className='formtitleContentBox'>Algemene gegevens</label>
                        <br></br>
                        <div className="contentbox">
                            <div className="formElement">
                                <label className='labelcolor'>
                                    Actief:
                                </label>
                                <input id="userActive" type='CheckBox' className="checkbox" value={user.actief} name="actief" onChange={changecheckbox} checked={user.actief} disabled={Edit}></input>
                            </div>
                            <div className="formElement">
                                <label className='labelcolor'>
                                    Voornaam:
                                </label>
                                <input id='voornaam' type='text' name="voornaam" value={user.voornaam} onChange={changevalue} disabled={Edit}></input>
                            </div>
                            <div className="formElement">
                                <label className='labelcolor'>
                                    Achternaam:
                                </label>
                                <input id="achternaam" type='text' name="achternaam" value={user.achternaam} onChange={changevalue} disabled={Edit}></input>
                            </div>
                            <div className="formElement">
                                <label className='labelcolor'>
                                    nr wkn:
                                </label>
                                <input id="nr_wkn" type='number' name="nr_wkn" value={user.nr_wkn} onChange={changevalue} disabled={Edit}></input>
                            </div></div>    <br></br>
                        <Tabs className="HotelTabs">
                            <TabList>
                                <Tab>Account</Tab>
                                <Tab>Persoons info</Tab>
                                <Tab>Adres gegevens</Tab>
                                <Tab>Hotels</Tab>
                            </TabList>
                            <TabPanel >
                                <div className='tabpanel UserTabs' id="Account">
                                    <div className="formElement">
                                        <label>
                                            Email:
                                        </label>
                                        <input id="email" type='Email' name="email" value={user.email} onChange={changevalue} autocomplete="off" disabled={Edit}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Paswoord:
                                        </label>
                                        <input id="paswoord" type='password' name="paswoord" value={user.password} onChange={changevalue} autocomplete="off" disabled={Edit}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Profiel:
                                        </label>
                                        <select id='profile' name="FK_Rechten_ID" value={user.FK_Rechten_ID} onChange={changevalue} required disabled={Edit}>
                                            <option ></option>
                                            <option value={2}>Administrator</option>
                                            <option value={3}>Supervisor</option>
                                            <option value={1} >Cleaner</option>


                                        </select>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel >
                                <div className='tabpanel UserTabs' id="Extra info">
                                    <div className="formElement">
                                        <label>
                                            rijksregisternummer:
                                        </label>
                                        <input id="rijksregisternummer" type='text' name="rijksregisternummer" value={user.rijksregisternummer} onChange={changevalue} disabled={Edit}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Geboorte datum:
                                        </label>
                                        <input id="geboortedatum" type='Date' name="geboortedatum" value={new Date(user.geboortedatum).toLocaleDateString('en-CA')} onChange={changevalue} disabled={Edit}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            in_dienst:
                                        </label>
                                        <input id="in_dienst" type='Date' name="in_dienst" value={new Date(user.in_dienst).toLocaleDateString('en-CA')} onChange={changevalue} disabled={Edit}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            uit_dienst:
                                        </label>
                                        <input id="uit_dienst" type='Date' name="uit_dienst" value={new Date(user.uit_dienst).toLocaleDateString('en-CA')} onChange={changevalue} disabled={Edit}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            afdeling nummer:
                                        </label>
                                        <input id="nr_afd" type='number' name="nr_afd" value={user.nr_afd} onChange={changevalue} disabled={Edit}></input>
                                    </div>
                                    <div id="uploaddiv" className="formElement">
                                        <label >
                                            Upload new contract:
                                        </label>

                                        <input id="uploadContract" type='file' accept='.pdf' onChange={(e) => { setContract(e.target.files[0]); document.getElementById("viewcontractButton").disabled = true }} disabled={Edit}></input>
                                    </div>
                                    <div id="viewcontractDiv" className="formElement">
                                        <Button type="button" id="viewcontractButton" className='Savebutton' onClick={openContract} disabled={!Edit} >View contract</Button>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel id="Address">
                                <div className='tabpanel UserTabs' >
                                    <div className="formElement">
                                        <label>
                                            Straat naam:
                                        </label>
                                        <input id="straatnaam" type='text' name="straatnaam" value={user.straatnaam} onChange={changevalue} disabled={Edit}></input>
                                    </div>
                                    <div className="formElement gridform4Cols">
                                        <label>
                                            huisnummer:
                                        </label>
                                        <input id="huisnummer" type='text' className="halfwidth" name="huisnummer" value={user.huisnummer} onChange={changevalue} disabled={Edit}></input>
                                        <label>
                                            Postbus:
                                        </label>
                                        <input id="bus" type='text' className="halfwidth" name="bus" value={user.bus} onChange={changevalue} disabled={Edit}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Gemeente:
                                        </label>
                                        <input id="gemeente" type='text' name="gemeente" value={user.gemeente} onChange={changevalue} disabled={Edit}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Zip code:
                                        </label>
                                        <input id="zipcode" type='number' name="zipcode" value={user.zipcode} onChange={changevalue} disabled={Edit}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Land:
                                        </label>
                                        <input id="land" type='text' name="land" value={user.land} onChange={changevalue} disabled={Edit}></input>
                                    </div>
                                    <div className="formElement">
                                        <label>
                                            Telefoon:
                                        </label>
                                        <input id="gsm" type='Phone' name="gsm" value={user.gsm} onChange={changevalue} disabled={Edit}></input>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className='tabpanel UserTabs ' id="hotelselection">
                                    <div className="listHeight">
                                        <div className={hotelcss.roomtableheader}>
                                            <p id="Hotel_ID">Hotel ID</p><p id="Hotel_Name">Hotel Name</p><p id="Linked">Linked</p></div>
                                        <div id="hotelsList">
                                            {hotelsarray}
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                        <div id="editDiv" className="formchildButton">
                            <Button id="editButton" className='' onClick={handleEdit}>Edit</Button>
                        </div>

                        <div id="saveDiv" class="two_Buttons">
                            <Button type="submit" id="saveButton" class="Savebutton btn btn-primary margin-right">Save</Button>
                            <Button type="button" id="cancelButton" class="Savebutton btn btn-primary margin-right" onClick={handleCancel} >Cancel</Button>
                            {/* //onClick={() => navigate("/employees")} */}
                        </div>
                    </form>
                </div>


            </div>
        </>
    )
}
export default EmplopyeePage