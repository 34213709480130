import React from "react"
import './css/createhotel.css';
import { getciaw } from "../dbconnector/connector";
import { setErrorMessage } from "../tools/errorhandling";
import { useState } from 'react';
import { useEffect } from 'react';
import hotelcss from './css/hotelhotellist.module.css';




const Ciaw = (props) => {

    const [ciaw, setCiaw] = useState([]);
    const [array, setArray] = useState([]);


    useEffect(() => {

        const response = getciaw()
        console.log(response)

        response.then(res => {

            console.log(res)


            setArray([])
            res.forEach((item, index) => {
                        const obj = JSON.parse(item.response)[0];
                        const payload = JSON.parse(item.message);
            if(obj.createdPresenceRegistration){
                if(obj.createdPresenceRegistration===null){
                    console.log(payload.items[0])
                    setArray(pre => [...pre, <div  className={hotelcss.ciawlinkError} >
                        <p className="overflow">failed</p>
                        <p className="overflow">{payload.items[0].type}</p>
                        <p className="overflow">{new Date(item.senddate).toLocaleDateString()} {new Date(item.senddate).toLocaleTimeString()}</p>
                        <p className="overflow">ssin: {payload.items[0].ssin}</p>
                        <p className={hotelcss.overflowresponse}>{obj.notCreatedPresenceRegistration.errorList[0].errorDescription}</p>                   
                    </div>]
                    )
    
                   }else{
                    
                    setArray(pre => [...pre, <div  className={index % 2 === 0 ? hotelcss.ciawlinkVarient1 : hotelcss.ciawlinkVarient2} >
                        <p className="overflow">success</p>
                        <p className="overflow">{obj.createdPresenceRegistration.type}</p>
                        <p className="overflow">{new Date(item.senddate).toLocaleDateString()} {new Date(item.senddate).toLocaleTimeString()}</p>
                        <p className="overflow">{obj.createdPresenceRegistration.worker.givenName} {obj.createdPresenceRegistration.worker.familyName}</p>
                        <p className="overflow">{item.response}</p>
                    </div>]
                    )
                   }

            }else{
                setArray(pre => [...pre, <div  className={hotelcss.ciawlinkError} >
                    <p className="overflow">failed</p>
                    <p className="overflow">{}</p>
                    <p className="overflow">{new Date(item.senddate).toLocaleDateString()} {new Date(item.senddate).toLocaleTimeString()}</p>
                    <p className="overflow">{item.fullname}</p>
                    <p className={hotelcss.overflowresponse}>{item.response}</p>                   
                </div>]
                )
            }
              
                
            })
            setCiaw(res)

        });

    }, []);


    return (
        <>
            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id='ciawlist' className='list'>
                    <div id="headertable" className={hotelcss.ciawtableheader}><p id="status">status</p><p id="type">type</p><p id="senddate">senddate</p><p id="Name">Name</p><p id="Response">response</p></div>
                    <div id="array" >{array}</div>
                </div>
            </div>
        </>
    )

}

export default Ciaw