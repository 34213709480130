
import React from "react"
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";
import Button from 'react-bootstrap/Button';
import UL from 'react-bootstrap/ListGroup';
import LI from 'react-bootstrap/ListGroupItem';



const extraFunctie = () =>{
    
}



const Roomtype = () => {

    // create navigation object if needed
    const navigate = useNavigate();



    //dop something after loading page
    useEffect(() => {

        try {
            // call on backend here if so that app does not crash and spews error code


            //simulatie van backendcall zodat code werkt
            const data = async () => {

            };

            //wachten op response van server. code binnen de brackets uitvoeren wanner de response er is (=callback function)
            data.then(response => {
                if (response === 200) {
                    //dosomething with data
                    const contentdiv = document.getElementById("content")
                } else {
                    throw new Error(response.status + ":" + response.message)
                }
            })
        } catch (error) {
            setErrorMessage(error.message)
        }


    }, []);

    return (
        <>
            <div id="main">
                <header>
                    <div id="errordiv" className='error'></div>
                </header>
                <div id="content" className='formwidth'>
                    <form className="form" >
                            <label className='formtitle'>
                                Room type
                            </label>
                        <div className="Roomformchild">
                            <label className='labelhotel'>
                                Id:
                            </label>
                            <input className="normalinput" type='text'  ></input>
                            </div>
                        <div className="Roomformchild">
                            <label className='labelhotel'>
                                Hotel Id:
                            </label>
                            <input className="normalinput" type='text'  ></input>
                        </div>
                        <div className="Roomformchild">
                            <label className='labelhotel'>
                                Type name:
                            </label>
                            <input className="normalinput" type='text'  ></input>
                        </div>
                        <div className="Roomformchild">
                            <label className='labelhotel'>
                                Type Description:
                            </label>
                            <textarea name="message" className="textarea doublegrid"></textarea>
                        </div>
                        <div className="Roomformchild_elipsis">
                            <label className='labelhotel'>
                                Cleaning types:
                            </label>
                            <UL>
                                <LI>Cleaning Type A</LI>
                                <LI>Cleaning Type B</LI>
                                <LI>Cleaning Type C</LI>
                                <LI>Cleaning Type D</LI>
                            </UL>
                            <Button id="saveButton" className='buttonelipsis' >...</Button>
                        </div>

                        <div className="formchildButton">
                            <Button id="saveButton" className='' >Add Cleaning Task</Button>
                        </div>
                        <div className="two_Buttons">
                            <Button id="saveButton" className='Savebutton' >Save</Button>
                            <Button id="cancelButton" className='Savebutton' >Cancel</Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )

}

export default Roomtype