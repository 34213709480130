import React from 'react';
import parse from 'html-react-parser';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './css/application.css';
import Navigatie from "./nav";

import { useEffect } from 'react';
import { setErrorMessage } from "../tools/errorhandling";
import { retrieveCheckinData } from '../dbconnector/connector'


const createcheckinlist = (list, t) => {
   

    let divlist = document.getElementById("list")
    //id divlist does not exist we need to create filters and create divlist otherwise we need to empty the divlist
    //this so that the data is not dupplicated
    if (divlist === null) {
        const upperdiv = document.getElementById("main");
        divlist = document.createElement("div");
        divlist.id = "list"
        /* #endregion */

        upperdiv.appendChild(divlist);
    } else {
        divlist.innerHTML = "";
    }

    /* #region  creating headings */

    const title = document.createElement("h1")
    divlist.appendChild(title)
    title.innerText = t('checkinTitle')

    const employediv = document.createElement("div")
    divlist.appendChild(employediv)


    const EMP_ID_div = document.createElement("p")
    EMP_ID_div.innerText = "ID"
    employediv.appendChild(EMP_ID_div)

    const name = document.createElement("p")
    employediv.appendChild(name)
    name.innerText = "Fullname"

    const timeElement = document.createElement("p");
    timeElement.innerText = " Time"
    employediv.appendChild(timeElement);
    employediv.className = "tableheader"
    const Type = document.createElement("p");
    Type.innerText = " Type"
    employediv.appendChild(Type);
    employediv.className = "tableheader gridCheckInList"

    /* #endregion */
    //create a list item for every element in the received array
    list.forEach((element, index) => {
        if (index < 10) {
            //create wrapper employeediv
            const employediv = document.createElement("div")
            divlist.appendChild(employediv)


            const EMP_ID_div = document.createElement("p")
            EMP_ID_div.innerText = element.nr_wkn
            employediv.appendChild(EMP_ID_div)

            const name = document.createElement("p")
            employediv.appendChild(name)
            name.innerText = element.voornaam + " " + element.achternaam


            const dateTime = new Date(element.loginDateTime)

            const timeElement = document.createElement("p");
            timeElement.innerText = dateTime.toLocaleTimeString();
            employediv.appendChild(timeElement);


            const Type = document.createElement("p");
            Type.innerText = element.type.toUpperCase()
            employediv.appendChild(Type);
            employediv.className = "tableheader"

            //dynamicaly add classname
            if (index % 2 === 0) {
                employediv.className = "linkVarient1 gridCheckInList"
            } else {
                employediv.className = "linkVarient2 gridCheckInList"
            }

        }
    });

}

//example
const Application  = (props) => {

   const  t  = props.translation;

    const checkinlist = retrieveCheckinData();


    const navigate = useNavigate()

    useEffect(() => {
        checkinlist.then(res => {
            createcheckinlist(res.checkins,t)
        })



    }, []);


    return (

        <div>
            <div id="main">
                <header><div id="errordiv" className='error'></div></header>


            </div>
        </div>
    )
}

export default Application;